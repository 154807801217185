import { AppError } from '../../models/app-error';
const errorCodeByStatus = {
    401: 'Unauthorized',
    403: 'Forbidden',
    413: 'ContentTooLarge',
    415: 'UnsupportedMediaType',
    429: 'RequestLimitExceeded'
};
export default function parseApiErrorResponse(response, responseBody) {
    var _responseBody_errors;
    if (responseBody === null || responseBody === void 0 ? void 0 : (_responseBody_errors = responseBody.errors) === null || _responseBody_errors === void 0 ? void 0 : _responseBody_errors.length) {
        return new AppError(responseBody);
    }
    const errorCode = errorCodeByStatus[response.status];
    if (errorCode) {
        return new AppError({
            code: errorCode
        });
    }
    return new AppError();
}
